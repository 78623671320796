import { AuthService } from "aurelia-auth";
import { User } from "components/models/UserModel";
import { autoinject, computedFrom, inject } from "aurelia-framework";
import { Router } from "aurelia-router";
import { SubscriptionHttpClient } from "http_clients/SubscriptionHttpClient";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { SingletonService } from "singleton";
import moment from "moment";

@autoinject
export class Subscription {
  private me: User;
  private freeTimeLeft: string;
  private timeBeforeExpiration: string;
  private loading: boolean;

  constructor(
    private authService: AuthService,
    private singleton: SingletonService,
    private router: Router,
    private subscriptionHttpClient: SubscriptionHttpClient
  ) {}

  async attached() {
    this.getMe();
    this.loading = true;
    await this.getMySubscription();
    this.loading = false;
  }

  async getMe() {
    this.me = this.singleton.getMe();
    this.freeSubscriptionTimeLeft();
    this.TimeLeftSubscription();
  }

  async getMySubscription() {
    try {

      const response = await this.subscriptionHttpClient.fetch("/subscription");
      let is200ok = await checkResponseStatus(response);
      const {
        docs: subs,
      }: { docs: Array<{ date_start: string; type: string }> } =
        await is200ok.json();
      if (subs.length == 0) {
        throw Error(
          "vous avez essayé de fetch les abonnements alors que l'utilisateur n'est pas abonnée"
        );
      }
      return subs[0];
    }
    catch(e) {
      console.log(e)
    }
  }

  async freeSubscriptionTimeLeft() {
    setInterval(() => {
      const dateInscr = Date.parse(this.me.createdAt); //

      const freeExpirationDate =
        moment() < moment("01062022", "DDMMYYYY")
          ? Date.parse(moment("01062022", "DDMMYYYY").format())
          : dateInscr + 7 * (60 * 60 * 24 * 1000);

      const timeLeft = freeExpirationDate - Date.now();

      const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
      const min = Math.floor((timeLeft / (1000 * 60)) % 60);
      const sec = Math.floor((timeLeft / 1000) % 60);

      this.freeTimeLeft = `${days}D ${hours}H ${min}m ${sec}s`;
    }, 500);
  }

  async TimeLeftSubscription() {
    const sub = await this.getMySubscription();
    const subStart = new Date(sub.date_start);

    const oneYearInMS = 365 * 24 * 60 * 60 * 1000;
    const oneMonthInMs = 31 * 24 * 60 * 60 * 1000;
    const duration = sub.type == "abonnemment" ? oneMonthInMs : oneYearInMS;

    const subEnd = subStart.getTime() + duration;

    setInterval(() => {
      const timeLeft = subEnd - Date.now();

      const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24);
      const min = Math.floor((timeLeft / (1000 * 60)) % 60);
      const sec = Math.floor((timeLeft / 1000) % 60);

      this.timeBeforeExpiration = `${days}D ${hours}H ${min}m ${sec}s`;
    }, 500);
  }

  async goToChooseSubscription() {
    this.router.navigateToRoute("choose_subscription");
  }
}
