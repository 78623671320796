// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/free.svg", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/pro.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./subscription.scss"></require>
  
  <section id="subscription" class="flex-column">
    <!-- <div id="miniheader" class="flex-row miniheader">
      <img
        id="thumbnail"
        click.delegate="router.navigateBack()"
        src="/static/img/arrow_left.svg"
      />
      <div class="flex-grow"></div>
      <h1 t="profile.subscription.title"></h1>
      <div class="flex-grow"></div>
    </div> -->
    <div class="subscription_type_container flex-row" class.bind="me.isSubscribed === false ? 'selected' : '' ">
      <div class="logo_grow flex-column">
        <div class="logo_bg">
          <img src="${___HTML_LOADER_IMPORT_0___}">
        </div>
      </div>
      <div class="price_type_features flex-column">
        <p class="price" t="profile.subscription.free_price">€0/year</p>
        <h4 t="profile.subscription.free_type_title">Free</h4>
        <p class="features" t="profile.subscription.free_features">1 OB, 1 BSV</p>
      </div>
    </div>
    <div class="subscription_type_container pro flex-row" class.bind="me.isSubscribed === true ? 'selected' : '' " click.delegate="goToChooseSubscription()">
      <div class="logo_grow flex-column">
        <div class="logo_bg">
          <img src="${___HTML_LOADER_IMPORT_1___}">
        </div>
      </div>
      <div class="price_type_features flex-column">
        <p class="price" t="profile.subscription.pro_price">€29/year</p>
        <h4 t="profile.subscription.pro_type_title">Pro</h4>
        <p class="features" t="profile.subscription.pro_features">X OB, X BSV</p>
      </div> 
    </div>
    <h3 if.bind="(me.isSubscribed == true)">\${timeBeforeExpiration} <span if.bind="(me.isSubscribed == true)" t="profile.subscription.time_left"></span></h3>
  </section>
</template>
`;
// Exports
export default code;